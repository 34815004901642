import React from "react"

import Layout from "src/layouts/inner"

const NotFoundPage = () => (
  <Layout title="Страница не найдена">
    <h1>Страница не найдена</h1>
    <p>Запрашиваемая вами страница не найдена.</p>
  </Layout>
)

export default NotFoundPage
